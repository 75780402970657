import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { getAllEvents } from "../data/events";
import EventsGrid from "../components/EventsGrid";
import FeaturedEventsCarousel from "../components/FeaturedEventsCarousel";
import EventsSearchBar from "../components/EventsSearchBar";

function AllEvents() {
  const [allEvents, setAllEvents] = useState([]);
  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const data = await getAllEvents();
      const liveData = data.filter((event) => event.isLive === true);
      setAllEvents(liveData);
      const featuredData = liveData.filter(
        (event) => event.isFeatured === true
      );
      setFeaturedEvents(featuredData);
      setIsLoading(false);
    }
    fetchData();
  }, []);

  return isLoading ? (
    <div className="flex items-center justify-center h-5/6 p-20">
      <TailSpin
        visible={true}
        height="80"
        width="80"
        color="#5271FF"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  ) : (
    <div>
      <div className="py-12 sm:py-4 mx-auto max-w-7xl px-6 lg:px-8">
        {featuredEvents.length > 0 && (
          <div>
            <h2 className="text-left py-2 text-3xl font-semibold tracking-tight text-primary sm:text-4xl">
              Featured Events
            </h2>
            <FeaturedEventsCarousel events={featuredEvents} />
          </div>
        )}
        <div className="flex flex-row flex-wrap items-center">
          <h1 className="w-1/2 text-left py-2 text-3xl font-semibold tracking-tight text-primary sm:text-4xl">
            All Events
          </h1>
          <div className="grow">
            <EventsSearchBar />
          </div>
        </div>

        <EventsGrid events={allEvents} />
      </div>
    </div>
  );
}

export default AllEvents;
