import React from "react";
import EventTile from "./EventTile";

function EventsGrid({ events }) {
  return (
    <div>
      <div className="mx-auto max-w-7xl ">
        <ul
          role="list"
          className="mx-auto mt-2 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
        >
          {events.map(
            (event) => event.posterImageUrl && <EventTile event={event} />
          )}
        </ul>
      </div>
    </div>
  );
}

export default EventsGrid;
