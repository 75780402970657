// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const secrets = process.env;
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: secrets.REACT_APP_FIREBASE_API_KEY,
  authDomain: secrets.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: secrets.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: secrets.REACT_APP_FIREBASE_STORAGE_BUCKET,

  messagingSenderId: secrets.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: secrets.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Export firestore database
export const db = getFirestore(app);
