import React from "react";

function EventTile({ event }) {
  return (
    <ul className="rounded-2xl bg-gray-100" key={event.id}>
      <a href={`/viewEvent?eventId=${event.id}`}>
        <img
          className="aspect-[1/1] w-full rounded-2xl object-cover"
          src={event.posterImageUrl}
          alt=""
        />
        <div className="my-6">
          <h3 className="text-lg font-bold leading-8 tracking-tight text-primary">
            {event.title}
          </h3>
          <p className="text-base leading-7 text-black">{event.location}</p>
          <p className="text-base leading-7 text-primary">
            {event.startTime && typeof event.startTime === "object"
              ? new Date(event.startTime.seconds * 1000).toLocaleDateString(
                  "en-US"
                )
              : new Date(event.startTime).toLocaleDateString("en-US")}{" "}
            -{" "}
            {event.endTime && typeof event.endTime === "object"
              ? new Date(event.endTime.seconds * 1000).toLocaleDateString(
                  "en-US"
                )
              : new Date(event.endTime).toLocaleDateString("en-US")}
          </p>
        </div>
      </a>
    </ul>
  );
}

export default EventTile;
