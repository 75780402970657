import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getEventBySearch } from "../data/events";
import EventsGrid from "../components/EventsGrid";
import EventsSearchBar from "../components/EventsSearchBar";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlass } from "react-loader-spinner";

function EventSearch() {
  const [searchParams] = useSearchParams();
  const searchString = searchParams.get("search");
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!searchString) {
      navigate("/events");
      return;
    }
    async function fetchData() {
      const data = await getEventBySearch(searchString);
      const liveData = data.filter(
        (event) =>
          event.isLive === true &&
          event.isCancelled === false &&
          event.isArchived === false
      );
      setEvents(liveData);
      setIsLoading(false);
    }
    fetchData();
  }, [searchString]);

  return isLoading ? (
    <div className="flex items-center justify-center h-5/6">
      <MagnifyingGlass
        visible={true}
        height="200"
        width="200"
        ariaLabel="magnifying-glass-loading"
        wrapperStyle={{}}
        wrapperClass="magnifying-glass-wrapper"
        glassColor="#c0efff"
        color="#5271FF"
      />
    </div>
  ) : (
    events && (
      <div className="py-12 sm:py-4 mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-left">
          <button
            onClick={() => navigate("/events")}
            className="text-primary bg-white outline outline-2 outline-primary hover:bg-primary hover:text-white font-medium rounded-lg text-sm px-4 py-2 dark:bg-white dark:hover:bg-primary dark:focus:ring-primary"
          >
            <ArrowUturnLeftIcon className="h-4 w-4 inline-block -mt-1 mr-2" />
            All Events
          </button>
        </div>
        <div className="flex flex-row flex-wrap items-center">
          <h1 className="w-1/2 text-left py-2 text-3xl font-semibold tracking-tight text-primary sm:text-4xl">
            Search Results
          </h1>
          <div className="grow">
            <EventsSearchBar toSearch={searchString} />
          </div>
        </div>
        <EventsGrid events={events} />
      </div>
    )
  );
}

export default EventSearch;
