import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header";
import AllEvents from "./pages/AllEvents";
import EventView from "./pages/EventView";
import EventSearch from "./pages/EventSearch";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Header />
      <Router>
        <main>
          <Routes>
            <Route path="/events" exact element={<AllEvents />} />
            <Route path="/viewEvent" exact element={<EventView />} />
            <Route path="/searchEvents" exact element={<EventSearch />} />
            <Route path="/" element={<Navigate to="/events" />} />
          </Routes>
        </main>
      </Router>
      <div className="d-grid gap-2 p-4 mt-12">
        <p className="text-center text-slate-600 text-sm font-extralight">
          Eventify 2024. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}

export default App;
