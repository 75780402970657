import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  MapPinIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import { TailSpin } from "react-loader-spinner";
import { getEventById } from "../data/events";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";

function EventView() {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get("eventId");
  const navigate = useNavigate();
  const [event, setEvent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!eventId) {
      navigate("/events");
      return;
    }
    async function fetchData() {
      const data = await getEventById(eventId);
      if (data === null || data === undefined) {
        navigate("/events");
        return;
      }
      setEvent(data);
      setIsLoading(false);
    }
    fetchData();
  }, []);

  var titleHtml = null;

  if (event.social === undefined || event.social === "") {
    titleHtml = (
      <h1 className="mt-2 text-3xl font-extralight tracking-tight text-gray-900 sm:text-4xl">
        {event.title}
      </h1>
    );
  } else {
    titleHtml = (
      <a target="_blank" href={event.social} style={{ textDecoration: "none" }}>
        <div className="flex flex-row items-center">
          <h1 className="text-3xl font-extralight tracking-tight text-gray-900 sm:text-4xl">
            {event.title}
          </h1>
          <ArrowTopRightOnSquareIcon className="mx-4 h-7 w-7 text-primary" />
        </div>
      </a>
    );
  }

  var eventTime =
    event.startTime &&
    new Date(event.startTime.seconds * 1000).toLocaleDateString("en-US");

  var eventLocation =
    event.location && event.location.trim().replaceAll(/\n/g, " ");
  var mapsUrl =
    event.location &&
    `https://www.google.com/maps/place/` + eventLocation.replaceAll(" ", "+");

  return isLoading ? (
    <div className="flex items-center justify-center h-5/6 p-20">
      <TailSpin
        visible={true}
        height="80"
        width="80"
        color="#5271FF"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  ) : (
    event.posterImageUrl && (
      <div>
        <div className="bg-white py-12 sm:py-4 mx-auto max-w-7xl px-6 lg:px-8">
          <div className="text-left my-4">
            <button
              onClick={() => navigate("/events")}
              className="text-primary bg-white outline outline-2 outline-primary hover:bg-primary hover:text-white font-medium rounded-lg text-sm px-4 py-2 dark:bg-white dark:hover:bg-primary dark:focus:ring-primary"
            >
              <ArrowUturnLeftIcon className="h-4 w-4 inline-block -mt-1 mr-2" />
              All Events
            </button>
          </div>
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-[40%_60%]">
            {/* Image Column */}
            <div className="lg:pr-4">
              <div className="mt-10 lg:col-start-2 lg:row-span-2 lg:mt-0 lg:self-center">
                <div className="aspect-h-1 aspect-w-1 overflow-hidden">
                  <img
                    src={event.posterImageUrl}
                    alt={event.title}
                    className="h-5/6 w-5/6 object-cover object-center"
                  />
                </div>
              </div>
            </div>
            {/* Event Info Column */}
            <div>
              <div className="text-left leading-7">
                {titleHtml}
                <h2 className="mt-2 text-2xl font-extralight tracking-tight text-gray-500 sm:text-2xl">
                  {eventTime}
                </h2>
                {/* Event Location */}
                <a target="_blank" href={mapsUrl}>
                  <div
                    className="grid lg:grid-cols-[5%_95%] sm:grid-cols-[5%_95%] py-4 px-4 mt-8 mr-4 bg-gray-300 rounded-lg"
                    style={{ borderRadius: "30px" }}
                  >
                    <MapPinIcon className="h-8 w-8" />
                    <div className="pl-2 text-primary font-medium">
                      {eventLocation}
                    </div>
                  </div>
                </a>
                {/* Event Info */}
                <div className="w-full">
                  <p className="mt-8 font-extrabold">Event Description</p>
                  <p className="mt-4">{event.description}</p>
                  <p className="mt-8 font-extrabold">
                    Interested in joining the event?
                  </p>
                  <p className="mt-4">Download the Eventify App!</p>
                  <div className="mt-12">
                    <a
                      href="https://www.helloeventify.com/eventify-app"
                      target="_blank"
                    >
                      <button
                        type="button"
                        className="flex w-full justify-center rounded-full bg-primary px-3 py-4 text-white text-base font-bold leading-6 shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                      >
                        Become a Vendor
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default EventView;
