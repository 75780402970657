import React, { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

function EventsSearchBar({ toSearch }) {
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState(toSearch || "");

  async function handleSearch(event) {
    event.preventDefault();
    navigate({
      pathname: "/searchEvents",
      search: createSearchParams({
        search: searchString,
      }).toString(),
    });
  }

  return (
    <form className="max-w-md ml-auto">
      <label
        htmlFor="default-search"
        className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
      >
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 text-bold" />
        </div>
        <input
          type="search"
          id="default-search"
          className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
          placeholder="Search Events..."
          required
          value={searchString}
          onChange={(e) => {
            e.preventDefault();
            setSearchString(e.target.value);
          }}
        />
        <button
          onClick={handleSearch}
          className="text-white absolute end-2.5 bottom-2.5 bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:primary font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary dark:hover:bg-primary dark:focus:ring-primary"
        >
          Search
        </button>
      </div>
    </form>
  );
}

export default EventsSearchBar;
