import {
  collection,
  getDocs,
  Timestamp,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "./firebase";
const algoliasearch = require("algoliasearch");
const secrets = process.env;

export async function getAllEvents() {
  const now = new Date();
  const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const timestamp = Timestamp.fromDate(date);

  const q = query(
    collection(db, "events"),
    where("startTime", ">=", timestamp),
    where("isCancelled", "==", false),
    where("isLive", "==", true),
    where("isArchived", "==", false)
  );

  var events = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    events.push(doc.data());
  });

  return events;
}

export async function getEventById(id) {
  const docRef = doc(db, "events", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) return docSnap.data();
  return null;
}

export async function getEventBySearch(searchString) {
  var today = new Date();
  today.setHours(0, 0, 0, 0);

  const ALGOLIA_ID = secrets.REACT_APP_ALGOLIA_ID;
  const ALGOLIA_SECRET_KEY = secrets.REACT_APP_ALGOLIA_SECRET_KEY;
  var client = algoliasearch(ALGOLIA_ID, ALGOLIA_SECRET_KEY);
  var index = client.initIndex("events");

  return index
    .search(searchString, {
      filters: `startTime > ${today.getTime()}`,
    })
    .then(function (responses) {
      // Response from Algolia:
      // https://www.algolia.com/doc/api-reference/api-methods/search/#response-format
      return responses.hits;
    });
}
