import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const navigation = [
  { name: "Events", href: "/events" },
  { name: "How it Works", href: "https://www.helloeventify.com/how-it-works" },
  { name: "About", href: "https://www.helloeventify.com/about" },
  { name: "FAQs", href: "https://www.helloeventify.com/faqs" },
  { name: "Blog", href: "https://content.helloeventify.com/blog" },
  { name: "Contact", href: "https://www.helloeventify.com/contact" },
];

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-6"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="https://www.helloeventify.com/" className="-m-1.5 p-1.5">
            <span className="sr-only">Eventify</span>
            <img
              className="h-10 w-auto"
              src="https://assets-global.website-files.com/653ad92da7dfaf7760867d1d/653bbbd2126d4df526ceddeb_Eventify-logo1.png"
              alt="Eventify Logo"
            />
          </a>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="uppercase text-sm font-light leading-6 text-gray-500"
            >
              {item.name}
            </a>
          ))}
        </div>
        <div className="flex flex-1 items-center justify-end gap-x-6">
          <a
            href="https://www.helloeventify.com/eventify-app"
            className="rounded-full uppercase px-6 py-3 text-sm font-extrabold bg-primary text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            Get App
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center gap-x-6">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Eventify</span>
              <img
                className="h-8 w-auto"
                src="https://assets-global.website-files.com/653ad92da7dfaf7760867d1d/653bbbd2126d4df526ceddeb_Eventify-logo1.png"
                alt="Eventify Logo"
              />
            </a>
            <a
              href="https://www.helloeventify.com/eventify-app"
              className="uppercase ml-auto rounded-full px-3 py-4 text-sm font-extrabold bg-primary text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Get App
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="rounded-full uppercase text-sm font-light -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
